import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"

const WordPressPostTemplate = ({ data }) => {
  const post = data.post
  return (
    <div style={{ textAlign: "center" }}>
      <img style={{ width: "100px" }} src={post.featuredImage.node.sourceUrl} />
      <div
        style={{ margin: "50px" }}
        dangerouslySetInnerHTML={{ __html: post.content }}
      ></div>
    </div>
  )
}

WordPressPostTemplate.propTypes = {
  data: PropTypes.any,
}

export default WordPressPostTemplate

export const pageQuery = graphql`
  query GetWpPost($id: String) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      date
      content
      featuredImage {
        node {
          sourceUrl
        }
      }
      categories {
        nodes {
          name
        }
      }
    }
  }
`
